//The basic page type representing article or page
export type Page = {
	//The type of this page
	type: PageType;
	//This pages url
	url: string;
	//Metadata used by this page
	metadata: any;
	//Body data returned for this page
	content: any;
	//Systemdata of the document
	systemdata: any;
};

//Represents the different types of pages
export enum PageType {
	ARTICLE = "article",
	PAGE = "page",
	REDIRECT = "redirect",
}
